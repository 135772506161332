/***********************************************************/
// 通用的缩放算法
// $event: 鼠标事件
// item: 用户数据，item.geometry可用
// stick :  left - 左
//          leftTop - 左上
//          top - 上
//          rightTop - 右上
//          right - 右
//          rightBottom - 右下
//          bottom - 下
//          leftBottom - 左下
//
//      leftTop ---------- top ----------- rightTop
//          |                                   |
//          |                                   |
//         left                               right
//          |                                   |
//          |                                   |
//       leftBottom ----- bottom ---------- rightBottom
//
//  boxElement: box框元素，可以用来计算box的位置和尺寸
var boxZoomStickDown = false;
//pt换算成px
function ptToPx(pt) {
    var px = pt * this.getDPI() / 72;
    return px;
}

function getDPI() {
    var arrDPI = new Array();
    if (window.screen.deviceXDPI != undefined) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
    } else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
    }
    return arrDPI[0];
}

function editorBoxZoom($event, item, stick, scale, boxElement, moveCallback, upCallback) {
    if (!item || !stick || !boxElement) {
        return;
    }
    var element;
    if (boxElement.offset) {
        // 元素组
        element = boxElement[0];
    } else if (boxElement.offsetLeft) {
        element = boxElement;
    }
    boxZoomStickDown = true;
    var l0 = element.offsetLeft;
    var t0 = element.offsetTop;
    var w0 = element.offsetWidth;
    var h0 = element.offsetHeight;
    var cx = l0 + w0 / 2;   // 原中心点x
    var cy = t0 + h0 / 2;   // 原中心点y
    var angle = Number(item.rotation.angle);
    var pt0 = rotatePoint({x: $event.clientX, y: $event.clientY}, {x: cx, y: cy}, -angle);
    var dx = 0;     // 框left变化
    var dy = 0;     // 框top变化
    var dw = 0;     // 框长变化
    var dh = 0;     // 框高变化
    var dcx = 0;    // 中心点x向偏移
    var dcy = 0;    // 中心点y向偏移

    var gx = item.geometry.x;
    var gy = item.geometry.y;
    var gw = item.geometry.width;
    var gh = item.geometry.height;
    if(item.offsetx || item.offsety){
        var nw = angular.copy(item.geometry.width);
        var nh = angular.copy(item.geometry.height);
        var nw1 = angular.copy(item.width);
        var nh1 = angular.copy(item.height);
        var x1 = angular.copy(item.offsetx);
        var y1 = angular.copy(item.offsety);
    }

    if(item.style){
        var nw = angular.copy(item.geometry.width);
        var nh = angular.copy(item.geometry.height);
        var _size = angular.copy(parseInt(item.style.size));
        var _lineheight = angular.copy(parseInt(item.style.lineheight));
    }


    function px2mm(pixels) {
        return (pixels || 0) * 25.4 / 96;
    }

    window.onmousemove = function (e) {
        if (!boxZoomStickDown) {
            e.stopPropagation();
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
        if (stick == 'left') {
            dx = pt1.x - pt0.x;
            dw = -dx;
            dcx = dx/2;
        }
        else if (stick == 'top') {
            dy = pt1.y - pt0.y;
            dh = -dy;
            dcy = dy/2;
        }
        else if (stick == 'right') {
            dw = pt1.x - pt0.x;
            dcx = dw / 2;
        }
        else if (stick == 'bottom') {
            dh = pt1.y - pt0.y;
            dcy = dh / 2;
        }
        else if (stick == 'leftTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dx = offsetX;
            dy = offsetY;

            dw = -dx;
            dh = -dy;
            dcx = dx / 2;
            dcy = dy / 2;
        }
        else if (stick == 'leftBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dx = offsetX;
            dw = -dx;
            dh = offsetY;
            dcx = dx / 2;
            dcy = dh / 2;
        }
        else if (stick == 'rightTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dy = offsetY;
            dw = offsetX;
            dh = -dy;
            dcx = dw / 2;
            dcy = dy / 2;
        }
        else if (stick == 'rightBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dw = offsetX;
            dh = offsetY;
            dcx = dw / 2;
            dcy = dh / 2;
        }
        else {
            console.error('zoom-dragging - invalid stick: ' + stick);
            e.stopPropagation();
            return;
        }
        // 如果框尺寸小于5mm, 不处理
        if (gw + (dw / scale) < 5 || gh + (dh / scale) < 5) {
            e.stopPropagation();
            return;
        }
        // 中心点偏移dc1
        var dc1 = rotatePoint({x: dcx, y: dcy}, {x: 0, y: 0}, angle);
        // 偏移补偿
        var cx_fix =  dc1.x - dcx;
        var cy_fix = dc1.y - dcy;

        if(item.style){
            if(item.geometry.width > ptToPx(item.style.size)){
                item.geometry.x = gx + ((dx + cx_fix) / scale);
                item.geometry.y = gy + ((dy + cy_fix) / scale);
            }
        }else{
            item.geometry.x = gx + ((dx + cx_fix) / scale);
            item.geometry.y = gy + ((dy + cy_fix) / scale);
        }
        // item.geometry.x = gx + ((dx + cx_fix) / scale);
        // item.geometry.y = gy + ((dy + cy_fix) / scale);
        item.geometry.width = gw + (dw / scale);
        item.geometry.height = gh + (dh / scale);
        if(item.offsetx || item.offsety){
            item.offsetx = ((gw + dw / scale) / (nw / nw1)) / (nw1 / x1);
            item.offsety = ((gh + dh / scale) / (nh / nh1)) / (nh1 / y1);
        }

        if(item.style){
            if((stick != 'left' && stick != 'top' && stick != 'right' && stick != 'bottom')){
                var size1 = Math.ceil((gw + (dw / scale)) / nw * _size);
                if(size1<6){
                    size1 = 6;
                    item.style.size = size1;
                    item.style.lineheight = Math.floor((size1 / _size * _lineheight));
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                    return false
                }
                item.style.size = size1;
                item.style.lineheight = Math.floor((size1 / _size * _lineheight));
            }
            if(stick == 'left'|| stick == 'right'){
                if(item.geometry.width < ptToPx(item.style.size)){
                    item.geometry.width = ptToPx(item.style.size)+10;
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                }
            }
            if(stick == 'top' || stick == 'bottom'){
                if(item.geometry.height < ptToPx(item.style.size)){
                    item.geometry.height = ptToPx(item.style.size)+10;
                    boxZoomStickDown = false;
                    if (upCallback) {
                        upCallback(item);
                    }
                }
            }
        }

        // if(item.style && (stick != 'left' && stick != 'top' && stick != 'right' && stick != 'bottom')){
        //     var size1 = Math.ceil((gw + (dw / scale)) / nw * _size);
        //     if (size1 <= 6) {
        //         size1 = 6
        //     }
        //     item.style.size = size1;
        //     item.style.lineheight = Math.floor((size1 / _size * _lineheight));
        // }

        // console.log('dx=' + dx + ", dy=" + dy + ', dw=' + dw + ', dh=' + dh);

        if (moveCallback) {
            moveCallback(item);
        }
        e.stopPropagation();
    }

    window.onmouseup = function (e) {
        if (!boxZoomStickDown) {
            e.stopPropagation();
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: cx, y: cy}, -angle);
        if (stick == 'left') {
            dx = pt1.x - pt0.x;
            dw = -dx;
            dcx = dx/2;
        }
        else if (stick == 'top') {
            dy = pt1.y - pt0.y;
            dh = -dy;
            dcy = dy/2;
        }
        else if (stick == 'right') {
            dw = pt1.x - pt0.x;
            dcx = dw / 2;
        }
        else if (stick == 'bottom') {
            dh = pt1.y - pt0.y;
            dcy = dh / 2;
        }
        else if (stick == 'leftTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dx = offsetX;
            dy = offsetY;

            dw = -dx;
            dh = -dy;
            dcx = dx / 2;
            dcy = dy / 2;
        }
        else if (stick == 'leftBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw - offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dx = offsetX;
            dw = -dx;
            dh = offsetY;
            dcx = dx / 2;
            dcy = dh / 2;
        }
        else if (stick == 'rightTop') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh - offsetY / scale) * gw ) {
                offsetX = -1 * offsetY * gw / gh;
            } else {
                offsetY = -1 * offsetX * gh / gw;
            }

            dy = offsetY;
            dw = offsetX;
            dh = -dy;
            dcx = dw / 2;
            dcy = dy / 2;
        }
        else if (stick == 'rightBottom') {
            var offsetX = pt1.x - pt0.x;
            var offsetY = pt1.y - pt0.y;
            if ((gw + offsetX / scale) * gh  > (gh + offsetY / scale) * gw ) {
                offsetX = offsetY * gw / gh;
            } else {
                offsetY = offsetX * gh / gw;
            }

            dw = offsetX;
            dh = offsetY;
            dcx = dw / 2;
            dcy = dh / 2;
        }
        else {
            console.error('zoom-dragging - invalid stick: ' + stick);
            e.stopPropagation();
            return;
        }

        // 如果框尺寸小于5mm, 不处理
        if (gw + (dw / scale) < 5 || gh + (dh / scale) < 5) {
            e.stopPropagation();
            return;
        }

        // 中心点偏移dc1
        var dc1 = rotatePoint({x: dcx, y: dcy}, {x: 0, y: 0}, angle);
        // 偏移补偿
        var cx_fix =  dc1.x - dcx;
        var cy_fix = dc1.y - dcy;

        if(!item.style){
            item.geometry.x = gx + ((dx + cx_fix) / scale);
            item.geometry.y = gy + ((dy + cy_fix) / scale);
            item.geometry.width = gw + (dw / scale);
            item.geometry.height = gh + (dh / scale);
        }

        if (upCallback) {
            upCallback(item);
        }
        boxZoomStickDown = false;
        e.stopPropagation();
    }
}


/*******************************************初始化*************************************************************************/
//防止事件冒泡，默认事件
function fn(e) {
    if(e){
        if (e && e.stopPropagation) {
            // 因此它支持W3C的stopPropagation()方法
            e.stopPropagation();
        } else {
            // 否则，我们需要使用IE的方式来取消事件冒泡
            window.event.cancelBubble = true;
        }

        // 阻止默认浏览器动作(W3C)
        if (e && e.preventDefault) {
            e.preventDefault();
        } else {
            // IE中阻止函数器默认动作的方式
            window.event.returnValue = false;
        }
    }
}
//dpi
var _dpi = null;
function js_getDPI() {
    if(_dpi){
        return _dpi
    }
    var arrDPI = new Array();
    if (window.screen.deviceXDPI != undefined) {
        arrDPI[0] = window.screen.deviceXDPI;
        arrDPI[1] = window.screen.deviceYDPI;
    } else {
        var tmpNode = document.createElement("DIV");
        tmpNode.style.cssText = "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
        document.body.appendChild(tmpNode);
        arrDPI[0] = parseInt(tmpNode.offsetWidth);
        arrDPI[1] = parseInt(tmpNode.offsetHeight);
        tmpNode.parentNode.removeChild(tmpNode);
    }
    _dpi = arrDPI[1];
    return arrDPI[1];
}
//mm转px
function transitionPx(item) {
    return item * js_getDPI() / 25.4;
}
//px转mm
function transitionMm(item) {
    return item * 25.4 / js_getDPI();
}
function imgWebFill(sum, photoSelectAll) {
    var _imgFillAll = angular.copy(photoSelectAll);
    return _imgFillAll[sum]
}

function addImagebox($http, item, callback) {
    var data=item.image.imageInfo;
    if(data){
        data = data;
        addImageboxCb(item, callback, data)
        return
    }
    $http({url: item.image.resource.identifier + '?imageInfo'}).success(function (data) {
        item.image.imageInfo = data;
        addImageboxCb(item, callback, data)
    }).error(function (error) {
    });
}
function addImageboxCb(item, callback, data) {
    item.image.angle = orientationInit1(data.orientation).angle;
    item.flip = orientationInit1(data.orientation).flip;
    data = orientationInit(data);
    // if ((item.geometry.width - item.geometry.height) * (data.width - data.height) < 0) {
    //     item.image.angle += 90;
    //     var _data = angular.copy(data);
    //     data.width = _data.height;
    //     data.height = _data.width;
    // }
    var imgR = item.geometry.width / item.geometry.height;
    var imgR1 = data.width / data.height;
    item.image.width1 = data.width;
    item.image.height1 = data.height;

    if (item.bokehtype == 'None') {
        if (imgR >= imgR1) {
            item.image.resource.adaptation = 'Height';
            item.image.width = item.geometry.width;
            item.image.height = item.geometry.width / data.width * data.height;
            item.image.offsetx = 0;
            item.image.offsety = -(item.image.height - item.geometry.height) / 2;
        } else {
            item.image.resource.adaptation = 'Width';
            item.image.width = item.geometry.height / data.height * data.width;
            item.image.height = item.geometry.height;
            item.image.offsetx = -(item.image.width - item.geometry.width) / 2;
            item.image.offsety = 0;
        }
    } else {
        if (imgR >= imgR1) {
            item.image.resource.adaptation = 'Height';
            item.image.width = item.geometry.width;
            item.image.height = item.geometry.width / data.width * data.height;
            item.image.offsetx = 0;
            item.image.offsety = 0;
            item.blurredrectangle = {
                width: item.geometry.height / data.height * data.width,
                height: item.geometry.height,
                x: (item.geometry.width - item.geometry.height / data.height * data.width) / 2,
                y: 0,
            }
        } else {
            item.image.resource.adaptation = 'Width';
            item.image.width = item.geometry.height / data.height * data.width;
            item.image.height = item.geometry.height;
            item.image.offsetx = 0;
            item.image.offsety = 0;
            item.blurredrectangle = {
                width: item.geometry.width,
                height: item.geometry.width / data.width * data.height,
                x: 0,
                y: (item.geometry.height - item.geometry.width / data.width * data.height) / 2,
            }
        }
    }
    if(callback){
        callback();
    }
}
function getBackgroundWebSource1($scope, res,pageW,pageH,item, bokehtype, callback) {
    var newImg = new Image();
    newImg.crossOrigin = "Anonymous";
    newImg.src = res.image.resource.identifier + '?imageMogr2/strip/rotate/' + item.angle+'/thumbnail/!40p';
    newImg.onload = function () {
        var imgR = pageW/pageH;
        var imgR1 = newImg.width/newImg.height;
        if(bokehtype == 'None'){
            if(imgR >= imgR1){
                item.resource.adaptation = 'Height';
                item.width= pageW;
                item.height= pageW/newImg.width*newImg.height;
            }else {
                item.resource.adaptation = 'Width';
                item.width= pageH/newImg.height * newImg.width;
                item.height= pageH;
            }
        }else {
            if(imgR >= imgR1){
                item.resource.adaptation = 'Height';
                item.width= pageW;
                item.height= pageW/newImg.width*newImg.height;
                item.offsetx= 0;
                item.offsety= 0;
            }else {
                item.resource.adaptation = 'Width';
                item.width= pageH/newImg.height*newImg.width;
                item.height= pageH;
                item.offsetx= 0;
                item.offsety= 0;
            }
        }
        if(callback){
            callback(item)
        }
        $scope.$apply();
    };
    newImg.onerror = function () {
        item.width = null;
        item.height = null;
        if(callback){
            callback()
        }
        $scope.$apply();
    }
}
function getBackgroundWebSource2($scope, res,pageW,pageH,item, bokehtype) {
    var newImg = new Image();
    newImg.crossOrigin = "Anonymous";
    newImg.src = res.identifier + '?imageMogr2/strip/rotate/' + item.angle+'/thumbnail/!40p';
    newImg.onload = function () {
        var imgR = pageW/pageH;
        var imgR1 = newImg.width/newImg.height;
        if(imgR >= imgR1){
            item.resource.adaptation = 'Height';
            item.width= pageW;
            item.height= pageW/newImg.width*newImg.height;
        }else {
            item.resource.adaptation = 'Width';
            item.width= pageH/newImg.height * newImg.width;
            item.height= pageH;
        }
        $scope.$apply();
    };
}
function getBackgroundWebSource3($scope, res, pageW, pageH, item, bokehtype, callback) {
    var newImg = new Image();
    newImg.crossOrigin = "Anonymous";
    newImg.src = res.image.resource.identifier + '?imageMogr2/strip/rotate/' + item.angle + '/thumbnail/!40p';
    newImg.onload = function () {
        var imgR = pageW / pageH;
        var imgR1 = newImg.width / newImg.height;
        if (bokehtype == 'None') {
            if (imgR >= imgR1) {
                item.resource.adaptation = 'Height';
                item.width = pageW;
                item.height = pageW / newImg.width * newImg.height;
                item.offsetx = 0;
                item.offsety = 0;
            } else {
                item.resource.adaptation = 'Width';
                item.width = pageH / newImg.height * newImg.width;
                item.height = pageH;
                item.offsetx = 0;
                item.offsety = 0;
            }
        } else {
            if (imgR >= imgR1) {
                item.resource.adaptation = 'Height';
                item.width = pageW;
                item.height = pageW / newImg.width * newImg.height;
                item.offsetx = 0;
                item.offsety = 0;
                res.blurredrectangle = {
                    width: pageH / newImg.height * newImg.width,
                    height: pageH,
                    x: (pageW - pageH / newImg.height * newImg.width) / 2,
                    y: 0,
                }
            } else {
                item.resource.adaptation = 'Width';
                item.width = pageH / newImg.height * newImg.width;
                item.height = pageH;
                item.offsetx = 0;
                item.offsety = 0;
                res.blurredrectangle = {
                    width: pageW,
                    height: pageW / newImg.width * newImg.height,
                    x: 0,
                    y: (pageH - pageW / newImg.width * newImg.height) / 2,
                }
            }
        }
        if(callback){
            callback()
        }
        $scope.$apply();
    };
    newImg.onerror = function () {
        item.width = null;
        item.height = null;
        if(callback){
            callback()
        }
        $scope.$apply();
    }
}

function orientationInit(item) {
    var data = angular.copy(item);
    if((item.orientation == "Bottom-right" )){
        data.width = item.width;
        data.height = item.height;
    }
    if (item.orientation == "Left-bottom" || item.orientation == "Left-top" || item.orientation == "Right-bottom"|| item.orientation == "Right-top") {
        data.width = item.height;
        data.height = item.width;
    }
    return data
}
function orientationInit1(orientation) {
    switch (orientation) {
        case 'Top-left':
            return {angle:0, flip:false};
            break;
        case 'Top-right':
            return {angle:0, flip:true};
            break;
        case 'Bottom-right':
            return {angle:180, flip:false};
            break;
        case 'Bottom-left':
            return {angle:180, flip:true};
            break;
        case 'Left-top':
            return {angle:90, flip:true};
            break;
        case 'Right-top':
            return {angle:90, flip:false};
            break;
        case 'Right-bottom':
            return {angle:270, flip:true};
            break;
        case 'Left-bottom':
            return {angle:270, flip:false};
            break;
        default:
            return {angle:0, flip:false};
    }
}
/*******************************************图片框处理*************************************************************************/
//缩放
var isZoomDown = false;
function imageboxZoom($scope, $event,item, direction,pro,index,slide, editbox, callback, callback1) {
    var x = $event.clientX;
    var y = $event.clientY;
    //获取w和h
    var nw = angular.copy(item.geometry.width) * pro;
    var nh = angular.copy(item.geometry.height) * pro;
    var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
    var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
    var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
    var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
    var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
    var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
    var x2 = item.geometry.x * pro;
    var y2 = item.geometry.y * pro;
    var _scale = item.image ? angular.copy(item.image.scale) : 1;
    // var maxX = (editbox.width + editbox.x) * pro;
    // var maxY = (editbox.height + editbox.y) * pro;
    var _left = $($event.target).parents('.selected').offset().left + (nw/2);
    var _top = $($event.target).parents('.selected').offset().top + (nh/2);

    var x3 = Math.abs(_left - $event.clientX);
    var y3 = Math.abs(_top - $event.clientY);
    var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));

    //开关打开
    isZoomDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isZoomDown == false) {
            return false;
        }
        var nl = 0;
        var x = Math.abs(_left + nl - e.clientX);
        var y = Math.abs(_top - e.clientY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        nl = (parseInt(z - distance))*2 / pro;
        var _w = (Number(nw) + Number(nl));
        var _h = _w / nw * nh;
        item.geometry.width = _w / pro;
        item.geometry.height = _h / pro;
        item.geometry.x = (x2 - (_w - nw)/2) / pro;
        item.geometry.y = (y2 - (_h - nh)/2) / pro;
        if (item.image) {
            item.image.width = _w / nw * nw2 / pro;
            item.image.height = _w / nw * nh2 / pro;
            item.image.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / pro;
            item.image.offsety = (_h / (nh / nh1)) / (nh1 / y1) / pro;
        }
        callback(item)
        $scope.$apply();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if(isZoomDown){
            callback1()
        }
        isZoomDown = false;
        $scope.$apply();
        $event.stopPropagation();
    }
}
function imageboxZoom1($scope, $event,item, direction, pro,index,slide, editbox, callback) {
    var x = $event.clientX;
    var y = $event.clientY;
    //获取w和h
    var nw = angular.copy(item.geometry.width) * pro;
    var nh = angular.copy(item.geometry.height) * pro;
    var nw1 = item.image ? angular.copy(item.image.width) * pro : 0;
    var nh1 = item.image ? angular.copy(item.image.height) * pro : 0;
    var nw2 = item.image ? angular.copy(item.image.width) * pro : 1;
    var nh2 = item.image ? angular.copy(item.image.height) * pro : 1;
    var x1 = item.image ? angular.copy(item.image.offsetx * pro) : 0;
    var y1 = item.image ? angular.copy(item.image.offsety * pro) : 0;
    var x2 = item.geometry.x * pro;
    var y2 = item.geometry.y * pro;
    var _scale = item.image ? angular.copy(item.image.scale) : 1;
    var maxX = editbox.width * pro;
    var maxY = editbox.height * pro;
    var _left = $($event.target).parents('.selected').offset().left + (nw/2);
    var _top = $($event.target).parents('.selected').offset().top + (nh/2);

    var x3 = Math.abs(_left - $event.clientX);
    var y3 = Math.abs(_top - $event.clientY);
    var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));
    //开关打开
    isZoomDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isZoomDown == false) {
            return;
        }
        //获取x和y
        var nl = 0;
        var x = Math.abs(_left + nl - e.clientX);
        var y = Math.abs(_top - e.clientY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        nl = (parseInt(z - distance))*2 / pro;
        var _w = (Number(nw) + Number(nl));
        var _h = (Number(nh) + Number(nl));
        if (item.image) {
            item.image.offsetx = 0;
            item.image.offsety = 0;
        }
        if (direction == 'left' || direction == 'right') {
            item.geometry.width = _w / pro;
            item.geometry.x = (x2 - (_w - nw)/2) / pro;
        }
        if (direction == 'top' || direction == 'bottom') {
            item.geometry.height = _h / pro;
            item.geometry.y = (y2 - (_h - nh)/2) / pro;
        }
        $scope.$apply();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if (isZoomDown){
            callback()
        }
        $event.stopPropagation();
        isZoomDown = false;
    }
}
//旋转
var isRotate = false;
function webRotate($scope, $event, item, _top, _left, ModulesService, callback) {
    //开关打开
    isRotate = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isRotate == false) {
            return;
        }
        item.rotation.angle = ModulesService.getAngle(_left,_top, e.clientX, e.clientY);

        item.rotation.angle = adsorbAngle(item.rotation.angle)
        $scope.$apply();
    }
    //鼠标抬起事件
    window.onmouseup = function (e) {
        if(isRotate){
            callback()
        }
        isRotate = false;
    };
}
/*******************************************素材处理*************************************************************************/
//添加素材
function webAddImageart($scope, $http,item, editbox, callback) {
    $http({url: item.resource.identifier + '?imageInfo'}).success(function (data) {
        var _imageart = {
            movable: true,
            editable: true,
            onlyshow: false,
            offsetx: '0',
            offsety: '0',
            transparency: '1',
            geometry: {
                x: '0',
                y: '0',
                width: data.width,
                height: data.height
            },
            rotation: {
                angle: '0'
            },
            resource: {
                provider: '',
                identifier: item.identifier,
            },
            width:item.width,
            height: item.height,
            imageInfo: data,
            scale: 1,
            useradded: true,
            type: 'dynamic',
            isArt: false,
            current:'imageart'
        }

        var bgImgW = data.width;
        var bgImgH = data.height;
        var bgImgR = bgImgW/bgImgH;
        if(bgImgR >= 1){
            if(bgImgW > 200){
                _imageart.geometry.width = 200;
                _imageart.geometry.height = _imageart.geometry.width * bgImgH / bgImgW;
            }else {
                _imageart.geometry.width = bgImgW;
                _imageart.geometry.height = bgImgH;
            }
        }else {
            if(bgImgH > 200){
                _imageart.geometry.height = 200;
                _imageart.geometry.width = _imageart.geometry.height * bgImgW / bgImgH;
            }else {
                _imageart.geometry.width = bgImgW;
                _imageart.geometry.height = bgImgH;
            }
        }
        _imageart.width = _imageart.geometry.width;
        _imageart.height = _imageart.geometry.height;
        _imageart.geometry.x = (editbox.width - _imageart.geometry.width) / 2 + editbox.x;
        _imageart.geometry.y = (editbox.height - _imageart.geometry.height) / 2 + editbox.y;
        // if(_arr&&_arr.e){
        //     var imgOffsetX=0,imgOffsetY=0;
        //     imgOffsetX = _arr.offsetX - _imageart.geometry.width * _arr.proW / 2;
        //     imgOffsetY = _arr.offsetY - _imageart.geometry.height * _arr.proH / 2;
        //     //拖拽边界判断
        //     if (imgOffsetX <= 0) {
        //         imgOffsetX = 0;
        //     }
        //
        //     if (imgOffsetX >= _arr.slideW - _imageart.geometry.width * _arr.proW) {
        //         imgOffsetX = _arr.slideW - _imageart.geometry.width * _arr.proW;
        //     }
        //
        //     if (imgOffsetY <= 0) {
        //         imgOffsetY = 0;
        //     }
        //
        //     if (imgOffsetY >= (_arr.slideH - _imageart.geometry.height * _arr.proH)) {
        //         imgOffsetY = _arr.slideH - _imageart.geometry.height * _arr.proH;
        //     }
        //     _imageart.geometry.x = imgOffsetX / _arr.proW;
        //     _imageart.geometry.y = imgOffsetY / _arr.proH;
        // }
        callback(_imageart)
    }).error(function (error) {});
}
//拖动
var isImageartDown = false;
var moveItem = [];
function moveWebdown($scope, $event, item, editbox, ratio, callback, moveCallback) {
    var x = $event.clientX;
    var y = $event.clientY;
    item.l = angular.copy(item.geometry.x * ratio);
    item.t = angular.copy(item.geometry.y * ratio);
    moveItem.push(item)
    //开关打开
    isImageartDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isImageartDown == false) {
            return;
        }
        //获取x和y
        moveItem.forEach(function (item1) {
            var nx = e.clientX;
            var ny = e.clientY;
            //计算移动后的左偏移量和顶部的偏移量
            var nl = nx - (x - item1.l);
            var nt = ny - (y - item1.t);
            // if(item.useradded){
            //     if(nl<editbox.x * ratio){ nl = editbox.x * ratio};
            //     if(nl > r){ nl = r};
            //     if(nt < editbox.y * ratio){ nt = editbox.y * ratio};
            //     if(nt > b){ nt = b};
            // }else {
            //     if(nl<0){ nl = 0};
            //     if(nl > r){ nl = r};
            //     if(nt < 0){ nt = 0};
            //     if(nt > b){ nt = b};
            // }

            item1.geometry.x = nl / ratio;
            item1.geometry.y = nt / ratio;
        })
        if(moveCallback){
            moveCallback()
        }
        $scope.$apply();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        moveItem = [];
        if(isImageartDown){
            callback()
        }
        isImageartDown = false;
        $event.stopPropagation();
    }
}
//素材缩放
var isImageartZoom = false;
function imageartZoom($scope, $event,item, direction, ratio,editbox, callback) {
    if(item.lock){return}
    var x = $event.clientX;
    var y = $event.clientY;
    //获取w和h
    var nw = angular.copy(item.geometry.width) * ratio;
    var nh = angular.copy(item.geometry.height) * ratio;
    var nw1 = angular.copy(item.width) * ratio;
    var nh1 = angular.copy(item.height) * ratio;
    var x1 = angular.copy(item.offsetx * ratio);
    var y1 = angular.copy(item.offsety * ratio);
    var x2 = item.geometry.x * ratio;
    var y2 = item.geometry.y * ratio;
    var _scale = angular.copy(item.scale);
    var _left = $($event.target).parents('.selected').offset().left + (nw/2);
    var _top = $($event.target).parents('.selected').offset().top + (nh/2);
    var x3 = Math.abs(_left - $event.clientX);
    var y3 = Math.abs(_top - $event.clientY);
    var distance = Math.sqrt(Math.pow(x3, 2) + Math.pow(y3, 2));
    //开关打开
    isImageartZoom = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isImageartZoom == false) {
            return;
        }
        var nl = 0;
        var x = Math.abs(_left + nl - e.clientX);
        var y = Math.abs(_top - e.clientY);
        var z = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
        nl = (parseInt(z - distance)) / ratio;
        var _w = (Number(nw) + Number(nl));
        var _h = _w / nw * nh;
        if(_w > 20){
            item.geometry.width = _w / ratio;
            item.geometry.height = _h / ratio;
            item.geometry.x = (x2 - (_w - nw)/2) / ratio;
            item.geometry.y = (y2 - (_h - nh)/2) / ratio;
            item.offsetx = (_w / (nw / nw1)) / (nw1 / x1) / ratio;
            item.offsety = (_h / (nh / nh1)) / (nh1 / y1) / ratio;
        }
        $scope.$apply();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if(isImageartZoom){
            callback()
        }
        isImageartZoom = false;
        $event.stopPropagation();
    }
}

/*******************************************文字处理*************************************************************************/
//添加文字
function textboxWebAdd(defaultFont, fontStyles, size, callback) {
    var _font = {
        geometry: {
            x: 0,
            y: 0,
            width: 0,
            height: 0
        },
        rotation: {
            angle: '0'
        },
        text: '双击输入文字',
        style: {
            font: defaultFont.family,
            size: size,
            color: '#000000',
            bold: false,
            italic: false,
            lineheight: size,
            letterspacing: 0,
            align: 'left',
            writingmode:'horizontal-tb'
        },
        fontuuid: defaultFont.uuid,
        identifier: "",
        editable: true,
        movable: true,
        lock: false,
        version: '2.0',
        transparency: '1',
        selected: true,
        loading: true,
        useradded: true,
        current: 'textbox',
    };
    var _fontStyle = [];
    for(var j=0; j<fontStyles.length; j++){
        _fontStyle.push(fontStyles[j].style)
    }
    if(_fontStyle.indexOf('NORMAL') != '-1'){
        _font.style.bold = false;
        _font.style.italic = false;
    }else {
        if(_fontStyle.indexOf('BOLD_ITALIC') != '-1'){
            _font.style.bold = true;
            _font.style.italic = true;
        }else {
            if(_fontStyle.indexOf('ITALIC') != '-1'){
                _font.style.italic = true;
                _font.style.bold = false;
            }
            if(_fontStyle.indexOf('BOLD') != '-1'){
                _font.style.bold = true;
                _font.style.italic = false;
            }
        }
    }
    callback(_font)
}

// ptSrc: 圆上某点(初始点);
// ptRotationCenter: 圆心点;
// angle: 旋转角度° -- [angle * M_PI / 180]:将角度换算为弧度
// 【注意】angle 逆时针为正，顺时针为负
function rotatePoint(ptSrc, ptRotationCenter, angle){
    var a = ptRotationCenter.x;
    var b = ptRotationCenter.y;
    var x0 = ptSrc.x;
    var y0 = ptSrc.y;
    var rx = a + (x0-a) * Math.cos(angle * Math.PI / 180) - (y0-b) * Math.sin(angle * Math.PI / 180);
    var ry = b + (x0-a) * Math.sin(angle * Math.PI / 180) + (y0-b) * Math.cos(angle * Math.PI / 180);
    var json = {x:rx,y:ry}
    return json;
}
function linePoint_by_x(x, pt0, pt1) {
    if (Math.abs(x - pt0.x) < 1) {
        return {x: x, y: pt0.y};
    }
    if (Math.abs(x - pt1.x < 1)) {
        return {x: x, y: pt1.y};
    }
    if (Math.abs(pt1.x - pt0.x) < 1) {
        return {x: x, y: pt1.y};
    }
    else {
        var y = (x - pt0.x) * (pt1.y - pt0.y) / (pt1.x - pt0.x) + pt0.y;
        return {x: x, y: y};
    }
}

function linePoint_by_y(y, pt0, pt1) {
    if (Math.abs(y - pt0.y) < 1) {
        return {x: pt0.x, y: y};
    }
    if (Math.abs(y - pt1.y) < 1) {
        return {x: pt1.x, y: y};
    }
    if (Math.abs(pt1.y - pt0.y) < 1) {
        return {x: pt1.x, y: y};
    }
    var x = (y - pt0.y) * (pt1.x - pt0.x) / (pt1.y - pt0.y) + pt0.x;
    return {x: x, y: y};

}
function linePoint(pt, pt0, pt1) {
    var pt_by_x = linePoint_by_x(pt.x, pt0, pt1);
    var pt_by_y = linePoint_by_y(pt.y, pt0, pt1);
    var distance1 = Math.pow(pt_by_x.x - pt0.x, 2) + Math.pow(pt_by_x.y - pt0.y, 2);
    var distance2 = Math.pow(pt_by_y.x - pt0.x, 2) + Math.pow(pt_by_y.y - pt0.y, 2);
    if (distance1 >= distance2) {
        return pt_by_x;
    } else {
        return pt_by_y;
    }
}

//文字左右按钮
var isTextDown = false;
function textboxWebZoom($event, item, element, ratio, editbox, callback1, callback2){
    if(item.lock){return}
    fn($event)
    var nw = angular.copy(item.geometry.width);
    var nh = angular.copy(item.geometry.height);
    var left = angular.copy(item.geometry.x);
    var top = angular.copy(item.geometry.y);
    var x2 = item.geometry.x;
    var y2 = item.geometry.y;
    var _cx = $($event.target).parents('.selected').offset().left + (nw*ratio/2);
    var _cy = $($event.target).parents('.selected').offset().top + (nh*ratio/2);
    var angle = Number(item.rotation.angle);
    var pt0 = rotatePoint({x: $event.clientX, y: $event.clientY}, {x: _cx, y: _cy}, -angle);
    // 鼠标起始点
    var x0 = pt0.x;
    var y0 = pt0.y;

    var dx0 = 1;
    var dy0 = 1;
    if (x0 < _cx) {
        dx0 = _cx - x0;
    } else {
        dx0 = x0 - _cx;
    }
    if (y0 < _cy) {
        dy0 = _cy - y0;
    } else {
        dy0 = y0 - _cy;
    }

    //开关打开
    isTextDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isTextDown == false) {
            return;
        }
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: _cx, y: _cy}, -angle);
        // 鼠标坐标
        var x1 = pt1.x;
        var y1 = pt1.y;

        var dx = 0;
        var dy = 0;
        if (x0 < _cx) {
            dx = _cx - x1;
        } else {
            dx = x1 - _cx;
        }
        if (y0 < _cy) {
            dy = _cy - y1;
        } else {
            dy = y1 - _cy;
        }
        var _w ;
        if (dx0 > 1) {
            _w = (Number(nw) + Number(nw * (dx - dx0)/dx0));
        } else {
            _w = nw;
        }
        var _h;
        if (dy0 > 1) {
            _h = (Number(nh) + Number(nh * (dy - dy0)/dy0));
        } else {
            _h = nh;
        }

        if (_w < 13.175) {
            _w = 13.175;
        }
        if (_h < 13.175) {
            _h = 13.175;
        }

        if (element == 'left' || element == 'right') {
            item.geometry.width = _w;
            item.geometry.x = (x2 - (_w - nw)/2);
        }else{
            item.geometry.height = _h;
            item.geometry.y = (y2 - (_h - nh)/2);
        }
        callback1(item)
        $event.stopPropagation();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if(isTextDown){
            callback2(item)
        }
        isTextDown = false;
        $event.stopPropagation();
    }
};
function textboxWebZoom1($event, item, element, ratio, callback1, callback2){
    if(item.lock){return}
    fn($event)
    //获取w和h
    var nw = angular.copy(item.geometry.width);
    var nh = angular.copy(item.geometry.height);

    var _size = angular.copy(parseInt(item.style.size));
    var _lineheight = angular.copy(parseInt(item.style.lineheight));
    // box起始点(mm)
    var x2 = item.geometry.x;
    var y2 = item.geometry.y;
    // 中心点(屏幕缩放)
    var target = $($event.target).parents('.selected');
    var _cx = target.offset().left + target.width() / 2;
    var _cy = target.offset().top + target.height() / 2;
    var angle = (Number(item.rotation.angle) + 360) % 360;
    var pt0 = rotatePoint({x: $event.clientX, y: $event.clientY}, {x: _cx, y: _cy}, -angle);

    // console.log("pt0", pt0, "c", {x: _cx, y: _cy}, angle);

    // 鼠标起始点
    var x0 = pt0.x;
    var y0 = pt0.y;

    var dx0 = 1;
    var dy0 = 1;
    switch (element) {
        case 'left': {// left-top
            dx0 = _cx - x0;
            dy0 = _cy - y0;
            break;
        }
        case "top": { // right-top
            dx0 = x0 - _cx;
            dy0 = _cy - y0;
            break;
        }
        case 'right':  { // right-bottom
            dx0 = x0 - _cx;
            dy0 = y0 - _cy;
            break;
        }
        case 'bottom':  {// left-bottom
            dx0 = _cx - x0;
            dy0 = y0 - _cy;
            break;
        }
        default:
            return;
    }
    var delta0 = Math.sqrt(dx0 * dx0 + dy0 * dy0);
    // if (x0 < _cx) {
    //     dx0 = _cx - x0;
    // } else {
    //     dx0 = x0 - _cx;
    // }
    // if (y0 < _cy) {
    //     dy0 = _cy - y0;
    // } else {
    //     dy0 = y0 - _cy;
    // }

    //开关打开
    isTextDown = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (isTextDown == false) {return;}
        // 鼠标坐标
        var pt1 = rotatePoint({x: e.clientX, y: e.clientY}, {x: _cx, y: _cy}, -angle);
        // pt1 = linePoint(pt11, {x: _cx, y: _cy}, pt0);
        // console.log("pt11", pt11, "l", pt1, "pt0", {x: _cx, y: _cy}, pt0, angle);
        var x1 = pt1.x;
        var y1 = pt1.y;

        var dx = 0;
        var dy = 0;
        switch (element) {
            case 'left': {// left-top
                dx = _cx - x1;
                dy = _cy - y1;
                break;
            }
            case "top": { // right-top
                dx = x1 - _cx;
                dy = _cy - y1;
                break;
            }
            case 'right':  { // right-bottom
                dx = x1 - _cx;
                dy = y1 - _cy;
                break;
            }
            case 'bottom':  {// left-bottom
                dx = _cx - x1;
                dy = y1 - _cy;
                break;
            }
            default:
                return;
        }
        if (dx*dx0 < 0 || dy*dy0 < 0) {
            return;
        }
        var delta = Math.sqrt(dx * dx + dy * dy);

        // if (x0 < _cx) {
        //     if (x1 > _cx) return;
        //     dx = _cx - x1;
        // } else {
        //     if (x1 < _cx) return;
        //     dx = x1 - _cx;
        // }
        // if (y0 < _cy) {
        //     if (y1 > _cy) return;
        //     dy = _cy - y1;
        // } else {
        //     if (y1 < _cy) return;
        //     dy = y1 - _cy;
        // }
        var _w ;
        if (Math.abs(delta0) > 1) {
            _w = Number(nw * delta / delta0);
        } else {
            _w = nw;
        }
        var _h;
        if (Math.abs(delta0) > 1) {
            _h = Number(nh * delta / delta0);
        } else {
            _h = nh;
        }

        var size1 = Math.ceil(_w / nw * _size);
        if (_w < 13.175) {
            _w = 13.175;
        }
        if (_h < 13.175) {
            _h = 13.175;
        }
        if (size1 < 9) {
            size1 = 9;
        }

        // console.log(element, x0, y0, x1, y1, _cx, _cy, angle, nw, nh, _w, _h, _size, size1);

        item.geometry.width = _w;
        item.geometry.height = _h;
        item.geometry.x = (x2 - (_w - nw)/2);
        item.geometry.y = (y2 - (_h - nh)/2);
        item.style.size = size1;
        item.style.lineheight = Math.floor((size1 / _size * _lineheight));
        callback1(item);
        $event.stopPropagation();
    }
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        if(isTextDown){
            callback2(item)
        }
        isTextDown = false;
        $event.stopPropagation();
    }
}
/*******************************************文字模板处理*************************************************************************/

var TjTools = {
    canvas: null
}
function getTextWidthInternal(text, fontWeight, fontStyle, fontSize, fontFamily) {
    // re-use canvas object for better performance
    var canvas = TjTools.canvas || (TjTools.canvas = document.createElement('canvas'));
    var context = canvas.getContext('2d');
    context.font = (fontWeight || 'normal') + ' ' +
        (fontSize + 'pt') + ' ' +
        (fontFamily || '') + ' ' + (fontStyle || 'normal');
    var metrics = context.measureText('陈');
    return metrics.height + 6;
}
function textDocWebAdd($scope, $http, level, textdocument, editbox, mediabox, callback, callback1) {
    level.textdocument = {
        background: textdocument.background,
        geometry: {
            width: transitionPx(textdocument.geometry.width),
            height: transitionPx(textdocument.geometry.height),
            x: transitionPx(textdocument.geometry.x),
            y: transitionPx(textdocument.geometry.y)
        },
        rotation: {
            angle:-textdocument.rotation.angle||0
        },
        current: 'textdocument',
        levels: angular.copy(textdocument.levels),
        lock: false,
        transparency: textdocument.transparency,
        movable: textdocument.movable,
        useradded:true,
        editable: textdocument.editable,
        scale:textdocument.geometry.width/mediabox.width,
    }
    if (level.textdocument.background && level.textdocument.background.resource &&level.textdocument.background.resource.identifier) {
        getBackgroundSource($http, level.textdocument.background.resource, level.textdocument.geometry.width, level.textdocument.geometry.height, level.textdocument.background, $scope);
        level.textdocument.background.offsetx = transitionPx(level.textdocument.background.offsetx);
        level.textdocument.background.offsety = transitionPx(level.textdocument.background.offsety);
        level.textdocument.background.type = 'Pic'
    }

    angular.forEach(level.textdocument.levels.level, function (lev1, l1) {
        (function (lev,l) {
            if (lev.imageart && lev.imageart.geometry) {
                lev.imageart.offsetx = transitionPx(lev.imageart.offsetx);
                lev.imageart.offsety = transitionPx(lev.imageart.offsety);
                lev.imageart.geometry = {
                    width: transitionPx(lev.imageart.geometry.width),
                    height: transitionPx(lev.imageart.geometry.height),
                    x: transitionPx(lev.imageart.geometry.x),
                    y: transitionPx(lev.imageart.geometry.y)
                };
                lev.imageart.rotation.angle = -lev.imageart.rotation.angle;
                if (lev.imageart.resource.identifier) {
                    getBackgroundSource($http, lev.imageart.resource, lev.imageart.geometry.width, lev.imageart.geometry.height, lev.imageart, $scope);
                }
            }
            if (lev.textbox && lev.textbox.geometry) {
                var text = lev.textbox;
                lev.textbox.geometry = {
                    width: transitionPx(lev.textbox.geometry.width),
                    height: transitionPx(lev.textbox.geometry.height),
                    x: transitionPx(lev.textbox.geometry.x),
                    y: transitionPx(lev.textbox.geometry.y)
                };
                lev.textbox.rotation.angle = -lev.textbox.rotation.angle;
            }
        })(lev1,l1)
    })
    level.textdocument.geometry.x = (editbox.width - level.textdocument.geometry.width) / 2 + editbox.x;
    level.textdocument.geometry.y = (editbox.height - level.textdocument.geometry.height) / 2 + editbox.y;
    callback(level)
}

//保存doments转换MM
function documentWebMM(template,text) {
    var _template = angular.copy(template);
    angular.forEach(_template.pages.page, function (page, index) {
        page.recommendLayout = null;
        if (page.editbox == null) {
            page.editbox = page.trimbox;
        }
        page.editbox = {
            width: transitionMm(page.editbox.width),
            height: transitionMm(page.editbox.height),
            x: transitionMm(page.editbox.x),
            y: transitionMm(page.editbox.y)
        };
        if(page.editboxes && page.editboxes.editbox){
            page.editboxes.editbox.forEach(function (editbox, editboxIndex) {
                page.editboxes.editbox[editboxIndex] = {
                    width: transitionMm(editbox.width),
                    height: transitionMm(editbox.height),
                    x: transitionMm(editbox.x),
                    y: transitionMm(editbox.y)
                }
            })
        }
        page.mediabox = {
            width: transitionMm(page.mediabox.width),
            height: transitionMm(page.mediabox.height),
            x: transitionMm(page.mediabox.x),
            y: transitionMm(page.mediabox.y)
        };
        page.trimbox = {
            width: transitionMm(page.trimbox.width),
            height: transitionMm(page.trimbox.height),
            x: transitionMm(page.trimbox.x),
            y: transitionMm(page.trimbox.y)
        };
        page.custom = {};
        if(page.scene){
            page.scene.geometry = {
                width: transitionMm(page.scene.geometry.width),
                height: transitionMm(page.scene.geometry.height),
                x: transitionMm(page.scene.geometry.x),
                y: transitionMm(page.scene.geometry.y)
            };
            page.scene.imagebox.geometry = {
                width: transitionMm(page.scene.imagebox.geometry.width),
                height: transitionMm(page.scene.imagebox.geometry.height),
                x: transitionMm(page.scene.imagebox.geometry.x),
                y: transitionMm(page.scene.imagebox.geometry.y)
            };
        }
        //条形码
        if(page.barcode){
            page.barcode.geometry = {
                width: transitionMm(page.barcode.geometry.width),
                height: transitionMm(page.barcode.geometry.height),
                x: transitionMm(page.barcode.geometry.x),
                y: transitionMm(page.barcode.geometry.y)
            };
        }
        if(page.qrcode){
            page.qrcode.geometry = {
                width: transitionMm(page.qrcode.geometry.width),
                height: transitionMm(page.qrcode.geometry.height),
                x: transitionMm(page.qrcode.geometry.x),
                y: transitionMm(page.qrcode.geometry.y)
            };
        }

        //背景
        if(page.background && page.background.resource && page.background.resource.identifier){
            page.background.offsetx = transitionMm(page.background.offsetx);
            page.background.offsety = transitionMm(page.background.offsety);
        }
        angular.forEach(page.levels.level, function (level, i) {
            if (level.code && level.code.geometry) {
                level.code.geometry = {
                    height: transitionMm(level.code.geometry.height),
                    width: transitionMm(level.code.geometry.width),
                    x: transitionMm(level.code.geometry.x),
                    y: transitionMm(level.code.geometry.y)
                };
            }
            if(level.imageart && level.imageart.geometry){
                level.imageart.rotation.angle = -level.imageart.rotation.angle;
                level.imageart.offsetx = transitionMm(level.imageart.offsetx);
                level.imageart.offsety = transitionMm(level.imageart.offsety);
                level.imageart.geometry = {
                    width: transitionMm(level.imageart.geometry.width),
                    height: transitionMm(level.imageart.geometry.height),
                    x: transitionMm(level.imageart.geometry.x),
                    y: transitionMm(level.imageart.geometry.y)
                };
            }
            if(level.imagebox && level.imagebox.geometry){
                level.imagebox.rotation.angle = -level.imagebox.rotation.angle;
                level.imagebox.geometry = {
                    width: transitionMm(level.imagebox.geometry.width),
                    height: transitionMm(level.imagebox.geometry.height),
                    x: transitionMm(level.imagebox.geometry.x),
                    y: transitionMm(level.imagebox.geometry.y)
                };
                if(level.imagebox.blurredrectangle){
                    level.imagebox.blurredrectangle = {
                        width: transitionMm(level.imagebox.blurredrectangle.width),
                        height: transitionMm(level.imagebox.blurredrectangle.height),
                        x: transitionMm(level.imagebox.blurredrectangle.x),
                        y: transitionMm(level.imagebox.blurredrectangle.y)
                    };
                }
                if(level.imagebox.image && level.imagebox.image.resource && level.imagebox.image.resource.identifier){
                    level.imagebox.image.width = transitionMm(level.imagebox.image.width);
                    level.imagebox.image.height = transitionMm(level.imagebox.image.height);
                    level.imagebox.image.offsetx = transitionMm(level.imagebox.image.offsetx);
                    level.imagebox.image.offsety = transitionMm(level.imagebox.image.offsety);

                    if(!_template.galleriesAll){
                        _template.galleriesAll = [];
                    }
                    _template.galleriesAll.push({
                        height: level.imagebox.image.height,
                        id: level.imagebox.image.resourceid,
                        identifier: level.imagebox.image.resource.identifier,
                        imgsize: level.imagebox.image.imgsize,
                        name: null,
                        provider: "qiniu",
                        usenum: 1,
                        width: level.imagebox.image.width
                    })
                }

            }
            if(level.textbox && level.textbox.geometry){
                if (!(level.textbox.text == '' || level.textbox.text == null || level.textbox.text == '双击输入文字' || level.textbox.text == '请输入文字' || level.textbox.text == text) && (level.textbox.geometry.width != 0 && level.textbox.geometry.height != 0)) {
                    level.textbox.rotation.angle = -level.textbox.rotation.angle;
                    level.textbox.geometry = {
                        width: transitionMm(level.textbox.geometry.width),
                        height: transitionMm(level.textbox.geometry.height),
                        x: transitionMm(level.textbox.geometry.x),
                        y: transitionMm(level.textbox.geometry.y)
                    };
                }else{
                    level.textbox = null;
                }
            }
            if (level.textdocument && level.textdocument.geometry) {
                level.textdocument.rotation.angle = -level.textdocument.rotation.angle;
                level.textdocument.geometry = {
                    width: transitionMm(level.textdocument.geometry.width),
                    height: transitionMm(level.textdocument.geometry.height),
                    x: transitionMm(level.textdocument.geometry.x),
                    y: transitionMm(level.textdocument.geometry.y)
                };

                var backg = level.textdocument.background;
                if(backg.resource.identifier){
                    backg.offsetx = transitionMm(backg.offsetx);
                    backg.offsety = transitionMm(backg.offsety);
                }

                angular.forEach(level.textdocument.levels.level, function (lev, l) {
                    if (lev.imageart && lev.imageart.geometry) {
                        var art = lev.imageart;
                        art.geometry={
                            width: transitionMm(art.geometry.width),
                            height: transitionMm(art.geometry.height),
                            x: transitionMm(art.geometry.x),
                            y: transitionMm(art.geometry.y)
                        };
                        art.offsetx= transitionMm(art.offsetx);
                        art.offsety= transitionMm(art.offsety);
                    }
                    if (lev.textbox && lev.textbox.geometry) {
                        var text = lev.textbox;
                        text.geometry={
                            width: transitionMm(text.geometry.width),
                            height: transitionMm(text.geometry.height),
                            x: transitionMm(text.geometry.x),
                            y: transitionMm(text.geometry.y)
                        };
                    }
                })
            }
            if (level.calTitleBox && level.calTitleBox.geometry) {
                var _calTitleBox = angular.copy(level.calTitleBox);
                level.calTitleBox.geometry = {
                    width: transitionMm(level.calTitleBox.geometry.width),
                    height: transitionMm(level.calTitleBox.geometry.height),
                    x: transitionMm(level.calTitleBox.geometry.x),
                    y: transitionMm(level.calTitleBox.geometry.y)
                };
                level.calTitleBox.rotation.angle = -level.calTitleBox.rotation.angle;
                level.calTitleBox.settings={
                    border:level.calTitleBox.settings.border,
                    items:{
                        item:angular.copy(_calTitleBox.settings.items.item?_calTitleBox.settings.items.item:_calTitleBox.settings.items)
                    }
                };
                level.calTitleBox.layout={
                    geometry: {
                        width:level.calTitleBox.layout.width,
                        height:level.calTitleBox.layout.height,
                        x:0,
                        y:0,
                    },
                    previewUrl:level.calTitleBox.layout.previewUrl,
                    type:level.calTitleBox.layout.type,
                    items:{
                        item:angular.copy(_calTitleBox.layout.items.item?_calTitleBox.layout.items.item:_calTitleBox.layout.items),
                    }
                };
                level.calTitleBox.layout.items.item.forEach(function (item) {
                    item.geometry = {
                        width:item.w,
                        height:item.h,
                        x:item.x,
                        y:item.y,
                    }
                })
                level.calTitleBox.settings.items.item.forEach(function (item) {
                    item.geometry = {
                        width:item.w>=0?item.w:item.geometry.width,
                        height:item.h>=0?item.h:item.geometry.height,
                        x:item.x>=0?item.x:item.geometry.x,
                        y:item.y>=0?item.y:item.geometry.y,
                    }
                })
            }
            if (level.calMonthBox && level.calMonthBox.geometry) {
                var _calMonthBox = angular.copy(level.calMonthBox);
                level.calMonthBox.geometry = {
                    width: transitionMm(level.calMonthBox.geometry.width),
                    height: transitionMm(level.calMonthBox.geometry.height),
                    x: transitionMm(level.calMonthBox.geometry.x),
                    y: transitionMm(level.calMonthBox.geometry.y)
                };
                level.calMonthBox.rotation.angle = -level.calMonthBox.rotation.angle;

                level.calMonthBox.weekStyles={item:angular.copy(_calMonthBox.weekStyles.item?_calMonthBox.weekStyles.item:_calMonthBox.weekStyles)};
                level.calMonthBox.weekLayout={
                    geometry: {
                        width:level.calMonthBox.weekLayout.width,
                        height:level.calMonthBox.weekLayout.height,
                        x:0,
                        y:0,
                    },
                    items:{
                        item:angular.copy(_calMonthBox.weekLayout.items.item?_calMonthBox.weekLayout.items.item:_calMonthBox.weekLayout.items)
                    },
                    type:level.calMonthBox.weekLayout.type,
                    previewUrl:level.calMonthBox.weekLayout.previewUrl,
                };
                level.calMonthBox.weekLayout.items.item.forEach(function (weekLayout) {
                    weekLayout.geometry = {
                        width:weekLayout.w,
                        height:weekLayout.h,
                        x:weekLayout.x,
                        y:weekLayout.y,
                    }
                })
                level.calMonthBox.dateStyles={item:angular.copy(_calMonthBox.dateStyles.item?_calMonthBox.dateStyles.item:_calMonthBox.dateStyles)},
                    level.calMonthBox.dateLayout={
                        geometry: {
                            width:level.calMonthBox.dateLayout.width,
                            height:level.calMonthBox.dateLayout.height,
                            x:0,
                            y:0,
                        },
                        items:{
                            item:angular.copy(_calMonthBox.dateLayout.items.item?_calMonthBox.dateLayout.items.item:_calMonthBox.dateLayout.items)
                        },
                        type:level.calMonthBox.dateLayout.type,
                        previewUrl:level.calMonthBox.dateLayout.previewUrl
                    };
                level.calMonthBox.dateLayout.items.item.forEach(function (dateLayout) {
                    dateLayout.geometry = {
                        width:dateLayout.w,
                        height:dateLayout.h,
                        x:dateLayout.x,
                        y:dateLayout.y,
                    }
                })
                level.calMonthBox.weekStyles.item.forEach(function (weekStyle) {
                    var _items = angular.copy(weekStyle.items.item?weekStyle.items.item:weekStyle.items);
                    _items.forEach(function (_item){
                        _item.geometry = {
                            width:_item.w,
                            height:_item.h,
                            x:_item.x,
                            y:_item.y,
                        }
                    })
                    weekStyle.items = {
                        item:_items
                    }
                })
                level.calMonthBox.dateStyles.item.forEach(function (dateStyle) {
                    var _items = angular.copy(dateStyle.items.item?dateStyle.items.item:dateStyle.items);
                    _items.forEach(function (_item){
                        _item.geometry = {
                            width:_item.w,
                            height:_item.h,
                            x:_item.x,
                            y:_item.y,
                        }
                    })
                    dateStyle.items = {
                        item:_items
                    }
                })
            }
        })
    })
    _template.version= '4.0';
    return _template;
}
//排序
function sortItem(item) {
    return function(object1, object2) {
        var value1 = object1[item];
        var value2 = object2[item];
        return value1 - value2;
    }
}

//移动画布
var isMoveCanvas = false;
function moveCanvas($event, callback) {
    var x = $event.clientX;
    var y = $event.clientY;
    isMoveCanvas = true;
    //鼠标移动
    window.onmousemove = function (e) {
        if (!isMoveCanvas) {
            return;
        }
        callback(e.clientX - x, e.clientY - y)
    };
    //鼠标抬起事件
    window.onmouseup = function ($event) {
        isMoveCanvas = false;
    }
}

function writingmodeInit(item) {
    if(item == 'vertical-lr' || item == 'vertical-rl'){
        return true
    }else{
        return false
    }
}

//判断元素是否在编辑区域内
function editboxIn(editboxs, item1, angle) {
    var item = angular.copy(item1);
    if(item1.border){
        if(item1.border.type == 'Color' && item1.border.lineWidth>0){
            item.x = Number(item.x) - Number(item1.border.lineWidth);
            item.y = Number(item.y) - Number(item1.border.lineWidth);
            item.width = Number(item.width) + Number(item1.border.lineWidth)*2;
            item.height = Number(item.height) - Number(item1.border.lineWidth)*2;
        }
        // if(item1.border.type == 'Pic'){
        //     item.x = Number(item.x) - (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
        //     item.y = Number(item.y) - (Number(imagebox.geometry.height) * Number(imagebox.border.edge.top) / 100)
        //     item.width = Number(item.width) + (Number(imagebox.geometry.width) * Number(imagebox.border.edge.left) / 100)
        //     item.height = Number(item.height) - Number(item1.border.lineWidth)*2
        // }
    }
    var exist = false;
    var _itemcx = item.x + item.width/2;
    var _itemcy = item.y + item.height/2;
    var _item={
        left: coordinateX(angle, item.x, item.y, _itemcx, _itemcy),
        top: coordinateX(angle,(item.x + item.width), item.y, _itemcx, _itemcy),
        right: coordinateX(angle,(item.x + item.width), (item.y + item.height), _itemcx, _itemcy),
        bottom: coordinateX(angle,item.x, (item.y + item.height), _itemcx, _itemcy),
    };
    var _editboxs=[];
    editboxs.forEach(function (editbox) {
        var cx = editbox.x + editbox.width/2;
        var cy = editbox.y + editbox.height/2;
        _editboxs.push({
            left: coordinateX(0, editbox.x, editbox.y, cx, cy),
            top: coordinateX(0,(editbox.x + editbox.width), editbox.y, cx, cy),
            right: coordinateX(0,(editbox.x + editbox.width), (editbox.y + editbox.height), cx, cy),
            bottom: coordinateX(0,editbox.x, (editbox.y + editbox.height), cx, cy),
        })
    });
    _editboxs.forEach(function (_editbox) {
        var _IsPointInMatrix = true;
        for(var key in _item){
            if(!IsPointInMatrix(_editbox.left,_editbox.top,_editbox.right,_editbox.bottom,_item[key])){
                _IsPointInMatrix = false;
                break
            }
        }
        if(_IsPointInMatrix){
            exist = true;
        }
    });
    return exist
}

function GetCross(p1, p2, p) {
    return (p2.x - p1.x) * (p.y - p1.y) - (p.x - p1.x) * (p2.y - p1.y);
}
//判断点p是否在p1p2p3p4的正方形内
function IsPointInMatrix(p1, p2, p3, p4, p) {
    var isPointIn = GetCross(p1, p2, p) * GetCross(p3, p4, p) >= 0 && GetCross(p2, p3, p) * GetCross(p4, p1, p) >= 0;
    return isPointIn;
}
// q - 旋转角度
// x1,y1 - 原始坐标
// x0，y0 - 中心点
// c - 两点距离
function coordinateX(q, x1, y1, x0, y0) {
    var angle = q || 0;
    var radius = angle * Math.PI / 180;
    var p2 = {
        x: (x1 - x0) * Math.cos(radius) + (y1 - y0) * Math.sin(radius) + x0,
        y: -(x1 - x0) * Math.sin(radius) + (y1 - y0) * Math.cos(radius) + y0
    };
    return p2;
}


/*
    x0,y0,左上角坐标
    x1,y1,右下角坐标
    angle,旋转角度
    return 旋转后大盒子宽高，坐标
*/
function outerRectangle(x0, y0, x1, y1, angle) {
    var angle = angle || 0;
    var cx = (x0 + x1) / 2;
    var cy = (y0 + y1) / 2;
    var pt0 = rotatePoint({x: x0, y: y0}, {x: cx, y: cy}, angle);
    var pt1 = rotatePoint({x: x1, y: y0}, {x: cx, y: cy}, angle);
    var pt2 = rotatePoint({x: x1, y: y1}, {x: cx, y: cy}, angle);
    var pt3 = rotatePoint({x: x0, y: y1}, {x: cx, y: cy}, angle);

    var minX = Math.min(pt0.x, pt1.x, pt2.x, pt3.x);
    var minY = Math.min(pt0.y, pt1.y, pt2.y, pt3.y);
    var maxX = Math.max(pt0.x, pt1.x, pt2.x, pt3.x);
    var maxY = Math.max(pt0.y, pt1.y, pt2.y, pt3.y);

    return {
        x: minX,
        y: minY,
        width: maxX - minX,
        height: maxY - minY
    };
}


//画布上下左右对齐，贴边
function elementAlign(item, angle, direction, pageWidth, pageHeight) {
    var x0 = item.x;
    var y0 = item.y;
    var x1 = item.x + item.width;
    var y1 = item.y + item.height;
    var outer = outerRectangle(x0, y0, x1, y1, angle);
    if(direction == 'left') {
        return (outer.width-item.width)/2
    }
    if(direction == 'right') {
        return pageWidth - item.width - (outer.width - item.width)/2
    }
    if(direction == 'top') {
        return (outer.height-item.height)/2
    }
    if(direction == 'bottom') {
        return pageHeight - item.height - (outer.height - item.height)/2
    }
}

/*
    计算旋转后的元素外框四点坐标
    x0,y0,左上角坐标
    x1,y1,右下角坐标
    angle,旋转角度
    return 旋转后大盒子宽高，坐标
*/
function outerRectangle2(x0, y0, x1, y1, angle) {
    var angle = angle || 0;
    var cx = (x0 + x1) / 2;
    var cy = (y0 + y1) / 2;
    var pt0 = rotatePoint({x: x0, y: y0}, {x: cx, y: cy}, angle);
    var pt1 = rotatePoint({x: x1, y: y0}, {x: cx, y: cy}, angle);
    var pt2 = rotatePoint({x: x1, y: y1}, {x: cx, y: cy}, angle);
    var pt3 = rotatePoint({x: x0, y: y1}, {x: cx, y: cy}, angle);

    var minX = Math.min(pt0.x, pt1.x, pt2.x, pt3.x);
    var minY = Math.min(pt0.y, pt1.y, pt2.y, pt3.y);
    var maxX = Math.max(pt0.x, pt1.x, pt2.x, pt3.x);
    var maxY = Math.max(pt0.y, pt1.y, pt2.y, pt3.y);

    return {
        minX: minX,
        minY: minY,
        maxX: maxX,
        maxY: maxY
    };
}


// 多元素水平垂直居中对齐
function horizontalAndVerticalCenter(attr, all){
    var allX = [];
    var allY = [];
    all.forEach(function(box){
        var outer = outerRectangle2(box.geometry.x, box.geometry.y, box.geometry.x + box.geometry.width, box.geometry.y + box.geometry.height, box.rotation.angle);
        allX.push(outer.minX);
        allY.push(outer.minY);
        allX.push(outer.maxX);
        allY.push(outer.maxY);
    })
    var minX = Math.min.apply(null, allX);
    var minY = Math.min.apply(null, allY);
    var maxX = Math.max.apply(null, allX);
    var maxY = Math.max.apply(null, allY);
    if (attr == 'vertical') {
        return maxY - (maxY - minY)/2;
    }
    if (attr == "standard") {
        return maxX - (maxX - minX)/2;
    }
}
